/*
 * Copyright 2010-2016 JetBrains s.r.o.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// a package is omitted to get declarations directly under the module

@JsName("throwNPE")
private fun throwNPE(message: String) {
    throw NullPointerException(message)
}

@JsName("throwCCE")
private fun throwCCE() {
    throw ClassCastException("Illegal cast")
}

@JsName("throwISE")
private fun throwISE(message: String) {
    throw IllegalStateException(message)
}

@JsName("throwUPAE")
private fun throwUPAE(propertyName: String) {
    throw UninitializedPropertyAccessException("lateinit property ${propertyName} has not been initialized")
}
